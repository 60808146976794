import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import StepContainer from './StepContainer';
import StepHeading from './StepHeading';
import { ParagraphText } from '../../shared/typography';
import registerIcon from '../../assets/register_step.png';

const Step3 = () => {
  return (
    <StepContainer>
      <StepHeading
        number={3}
        numberColor={palette.light1}
        numberBackground={palette.dark1}
        title="Completing Enrollment"
        titleColor={palette.dark1}
      />
      <BodyContainer>
        <div>
          <ParagraphText style={{ flexGrow: 1 }}>
            The student will finalize enrollment by ordering their wearable. We
            will confirm the order and send the dates for the Intro to WearDuke
            event to be held during orientation week. All students will be
            expected to attend the WearDuke orientation. If students do not
            complete the Baseline Survey and order their wearable prior to
            August 9, they will be provided with whatever style wearable we have
            in stock during orientation.
          </ParagraphText>
        </div>
        <Icon src={registerIcon} alt="Register Step Icon" />
      </BodyContainer>
    </StepContainer>
  );
};

export default Step3;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const Icon = styled.img`
  height: 35rem;
  margin-left: 2rem;

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    max-width: 30rem;
    margin-bottom: 1rem;
    margin-left: 0rem;
  }
`;
