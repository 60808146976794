import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { center } from '../shared/mixins';
import palette from '../shared/palette';
import { PrimaryHeading, ParagraphText } from '../shared/typography';

const Compensation = () => {
  return (
    <Container>
      <CompensationHeading>
        For participating, you will receive:
      </CompensationHeading>
      <ListContainer>
        <Bullet>&#9670;</Bullet>
        <ListItem>
          a <BoldedText>FREE WEARABLE</BoldedText> at the end of the academic
          year, upon successful completion of the study
        </ListItem>
        <Bullet>&#9670;</Bullet>
        <ListItem>
          periodic rewards (e.g. food points, Duke merchandise, etc.) upon
          completion of surveys
        </ListItem>
      </ListContainer>
      <ParagraphText>
        *if participants do not adhere to{' '}
        <Link to="initiative/#policies" style={{ textDecoration: 'none' }}>
          <PoliciesLink>Policies</PoliciesLink>
        </Link>
        , they may not be eligible for rewards
      </ParagraphText>
    </Container>
  );
};

export default Compensation;

const Container = styled.div`
  padding: 10rem 0rem;
  ${center}
`;

const CompensationHeading = styled(PrimaryHeading)`
  text-align: left;
`;

const ListContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-left: 5rem;
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;

  @media (max-width: 500px) {
    padding-left: 3rem;
  }
`;

const Bullet = styled.p`
  color: ${palette.dark1};
  font-size: 2rem;
  align-self: flex-start;
  transform: translateY(0.75rem);
`;

const ListItem = styled(ParagraphText)`
  align-self: flex-end;
`;

const BoldedText = styled.span`
  font-weight: 600;

  @media (max-width: 500px) {
    font-weight: 800;
  }
`;

const PoliciesLink = styled.a`
  cursor: pointer;
  border-bottom: 1px solid ${palette.dark1};
  color: ${palette.dark1};
`;
