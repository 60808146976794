import React from 'react';
import styled from 'styled-components';

import palette from '../shared/palette';
import { PrimaryHeading } from '../shared/typography';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

const Steps = () => {
  return (
    <Container id="steps">
      <StepsHeading>Next Steps: </StepsHeading>
      <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
    </Container>
  );
};

export default Steps;

const Container = styled.div`
  padding: 10rem 0rem;
  background-color: ${palette.light2};
`;

const StepsHeading = styled(PrimaryHeading)`
  && {
    color: ${palette.dark1};
    text-align: left;
    margin: 0 auto;
    margin-bottom: 5rem;
    width: 95%;
    max-width: 105rem;
  }
`;
