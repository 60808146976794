import React from 'react';

import Page from '../shared/Page';
import Eligibility from './Eligibility';
import Steps from './Steps';
import Compensation from './Compensation';

const ParticipantsPage = () => {
  return (
    <Page currPage="/participants">
      <Eligibility />
      <Steps />
      <Compensation />
    </Page>
  );
};

export default ParticipantsPage;
