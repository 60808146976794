import React from 'react';

import Layout from '../components/layout';
import ParticipantsPage from '../ParticipantsPage/ParticipantsPage';

export default () => (
  <Layout>
    <ParticipantsPage />
  </Layout>
);
