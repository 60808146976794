import styled from 'styled-components';

import palette from '../../shared/palette';

const StepContainer = styled.div`
  padding: 4rem;
  width: 95%;
  margin: 3rem auto;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background-color: ${palette.light1};
  border-radius: 6px;
  max-width: 105rem;

  @media (max-width: 500px) {
    padding: 4rem 2rem;
  }
`;

export default StepContainer;
