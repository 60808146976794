import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import StepContainer from './StepContainer';
import StepHeading from './StepHeading';
import { ParagraphText } from '../../shared/typography';
import consentIcon from '../../assets/consent_step.png';
import { DesktopEnrollButton } from '../../shared/Navbar/Desktop';

const Step1 = () => {
  return (
    <StepContainer>
      <StepHeading
        number={1}
        numberColor={palette.light1}
        numberBackground={palette.dark1}
        title="Consent"
        titleColor={palette.dark1}
      />
      <BodyContainer>
        <div style={{ flexGrow: 1 }}>
          <ParagraphText style={{ marginBottom: '3rem' }}>
            If the student meets the eligibility requirements, they may start
            the study enrollment process. To be eligible, the student must be an
            incoming Duke freshman residing in Gilbert-Addoms.
          </ParagraphText>
          {/* <a href="/enroll">
            <DesktopEnrollButton style={{ fontSize: '1.75rem' }}>
              {' '}
              Enroll Now{' '}
            </DesktopEnrollButton>
          </a> */}
        </div>
        <Icon src={consentIcon} alt="Consent Step Icon" />
      </BodyContainer>
    </StepContainer>
  );
};

export default Step1;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

// const BoldedText = styled(ParagraphText)`
//   font-weight: 700;
// `;

const Icon = styled.img`
  height: 35rem;
  margin-left: 2rem;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 30rem;
    height: auto;
    margin-bottom: 2rem;
    margin-left: 0;
  }
`;

const EnrollCenterWrapper = styled.div`
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const EnrollButton = styled.button`
  padding: 2rem 3rem;
  font-size: 2rem;
  background-color: ${palette.dark2};
  outline: none;
  border: none;
  border-radius: 6px;
  color: ${palette.light1};
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.5px;
  transition: 0.3s ease;

  @media (max-width: 800px) {
    margin: 0 auto;
  }

  :hover {
    transform: scale(1.02);
  }

  :active {
    transform: scale(1);
  }
`;
