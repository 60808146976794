import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import StepContainer from './StepContainer';
import StepHeading from './StepHeading';
import { ParagraphText } from '../../shared/typography';
import downloadIcon from '../../assets/download_step.png';

const Step2 = () => {
  return (
    <StepContainer>
      <StepHeading
        number={2}
        numberColor={palette.light1}
        numberBackground={palette.dark2}
        title="Complete Baseline Survey"
        titleColor={palette.dark2}
      />
      <BodyContainer>
        <div>
          <ParagraphText
            style={{ marginBottom: '2rem', color: palette.dark2, flexGrow: 1 }}
          >
            Once the participant has consented to participating in WearDuke,
            they will receive instructions on how to complete the initial
            Baseline Survey. We recommend the survey be completed prior to
            arriving on campus.
          </ParagraphText>
        </div>
        <Icon src={downloadIcon} alt="Download Step Icon" />
      </BodyContainer>
    </StepContainer>
  );
};

export default Step2;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

// const AppButton = styled.button`
//   padding: 2rem 3rem;
//   font-size: 2rem;
//   background-color: ${palette.dark2};
//   outline: none;
//   border: none;
//   border-radius: 6px;
//   color: ${palette.light1};
//   font-family: 'Montserrat', sans-serif;
//   letter-spacing: 0.5px;

//   @media (max-width: 800px) {
//     margin: 0 auto;
//   }
// `;

const Icon = styled.img`
  height: 40rem;
  margin-left: 2rem;

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    max-width: 30rem;
    margin-bottom: 2rem;
    margin-left: 0;
  }
`;
