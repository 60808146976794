import React from 'react';
import styled from 'styled-components';

const StepHeading = props => {
  return (
    <Container>
      <StepNumber background={props.numberBackground} color={props.numberColor}>
        <span>{props.number}</span>
      </StepNumber>
      <StepTitle color={props.titleColor}>{props.title}</StepTitle>
    </Container>
  );
};

export default StepHeading;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const StepNumber = styled.div`
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  text-align: center;
  border-radius: 50%;
  color: ${props => props.color};
  background-color: ${props => props.background};
  font-size: 3rem;
  margin-right: 2rem;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 500px) {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
  }
`;

const StepTitle = styled.h1`
  display: inline-block;
  color: ${props => props.color};
  font-size: 4rem;
  letter-spacing: 1px;
  font-weight: 400;

  @media (max-width: 500px) {
    font-size: 3rem;
  }
`;
