import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import StepContainer from './StepContainer';
import StepHeading from './StepHeading';
import { ParagraphText } from '../../shared/typography';
import wearableIcon from '../../assets/wearable_step.png';

const Step4 = () => {
  return (
    <StepContainer>
      <StepHeading
        number={4}
        numberColor={palette.light1}
        numberBackground={palette.dark2}
        title="Receive Wearable"
        titleColor={palette.dark2}
      />
      <BodyContainer>
        <ParagraphText style={{ color: palette.dark2, flexGrow: 1 }}>
          Participants will receive their wearables during Orientation Week at
          the <ItalicText>Intro to WearDuke</ItalicText> event. The exact date
          and time of this event will be announced via email.
        </ParagraphText>
        <Icon src={wearableIcon} alt="Wearable Step Icon" />
      </BodyContainer>
    </StepContainer>
  );
};

export default Step4;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const Icon = styled.img`
  height: 40rem;
  margin-left: 2rem;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 30rem;
    height: auto;
    transform: translateX(-1rem);
    margin-bottom: 2rem;
  }
`;
