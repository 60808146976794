import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { center } from '../shared/mixins';
import palette from '../shared/palette';
import { PrimaryHeading, ParagraphText } from '../shared/typography';
import dormIcon from '../assets/req_dorm.png';
import yearIcon from '../assets/req_year.png';
import wearableIcon from '../assets/req_wearable.png';

const Eligibility = () => {
  return (
    <Container>
      <EligibilityHeading>
        To participate in the first year pilot study the student must be...
      </EligibilityHeading>
      <ReqContainer>
        <ReqIcon src={dormIcon} alt="Dorm Requirement Icon" />
        <ReqIcon src={yearIcon} alt="Year Requirement Icon" />
        <ReqIcon src={wearableIcon} alt="Wearable Requirement Icon" />
      </ReqContainer>
      <ParagraphText style={{ fontWeight: '600' }}>
        No prior possession of wearables required to participate! For more
        information about the study, please see the{' '}
        <PageLink to="/initiative">
          <span>Initiative Details</span>
        </PageLink>{' '}
        page or the{' '}
        <PageLink to="/faq">
          <span>FAQ</span>
        </PageLink>
        .
      </ParagraphText>
    </Container>
  );
};

export default Eligibility;

const Container = styled.div`
  background-color: ${palette.light1};
  margin: 0 auto;
  width: 95%;
  max-width: 105rem;
  padding: 10rem 0rem;
  ${center}
`;

const EligibilityHeading = styled(PrimaryHeading)`
  && {
    text-align: left;
    margin: 0;
    margin-bottom: 5rem;
  }
`;

const ReqContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
`;

const ReqIcon = styled.img`
  width: 30rem;
  height: 30rem;
`;

const PageLink = styled(Link)`
  border-bottom: 1px solid ${palette.dark1};
  color: ${palette.dark1};
  text-decoration: none;
`;
